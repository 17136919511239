.desktop {
  display: block;
}
.mobile {
  display: none;
}

.mobile-curve {
  display: none;
}
.color-blue {
  color: #001e53;
}

.bg-blue {
  background-color: #001e53;
}
.bg-grey {
  background-color: #f0efe7;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #f0efe7;
}
html {
  scroll-behavior: smooth;
}

p {
  font-family: "roc-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
  opacity: 0;
}
.header-text {
  position: absolute;
  top: 52px;
  left: 74px;
  height: 16px;
}
.header-logo {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
}
.intro-arrow {
  position: absolute;
  border: none;
  background-color: transparent;
  width: 34px;
  height: 18px;
  padding: 0;
  left: 50%;
  bottom: 30px;
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
  background-repeat: no-repeat;
  background-image: url(../imgs/down_arrow.svg);
  background-size: 100% 100%;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-name: arrowBounce;
  animation-timing-function: ease-in-out;
}
@keyframes arrowBounce {
  from {
    transform: translate3d(-50%, 0, 0);
  }
  to {
    transform: translate3d(-50%, 6px, 0);
  }
}

#window-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  transition: transform 2s 1s ease-in-out, opacity 1s 2s ease-in;
}
#window-container.active {
  transform: scale(10, 10);
  opacity: 0;
}
.window-center {
  width: 451px;
  height: auto;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  z-index: 3;
}
.window-inner {
  width: 212px;
  height: 252px;
  position: relative;
  margin: 0 auto;
}
.window-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background-image: url(../imgs/window.svg);
  opacity: 0;
  z-index: 4;
}
.window-mask {
  background-color: #001e53;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background-image: url(../imgs/window_mask.png);
}
.window-text {
  width: 100%;
  height: auto;
  position: relative;
  margin: 60px 0 0;
  opacity: 0;
}
.window-bg {
  position: absolute;
  background-color: #001e53;
}
.window-top {
  top: 1px;
  left: -5%;
  width: 110%;
  height: 60vh;
  transform: translateY(-100%);
}
.window-bottom {
  bottom: 1px;
  left: -5%;
  width: 110%;
  height: 60vh;
  transform: translateY(100%);
}
.window-left {
  top: 0px;
  left: 1px;
  width: 60vw;
  height: 200vh;
  transform: translate3d(-100%, -50%, 0);
}
.window-right {
  top: 0px;
  right: 1px;
  width: 60vw;
  height: 200vh;
  transform: translate3d(100%, -50%, 0);
}

.intro-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #001e53;
}
.main-img {
  position: absolute;
  left: -10000%;
  right: -10000%;
  top: -10000%;
  bottom: -10000%;
  margin: auto auto;
  min-width: 1000%;
  min-height: 1000%;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
}

.content-container {
  position: relative;
  width: 100%;
}
/* .content-container .container {
    position: relative;
    z-index: 2;
    height: 100%;
} */

.content-intro {
  height: 100vh;
  overflow: hidden;
}

.section-main {
  position: relative;
  height: auto;
  z-index: 1;
  padding: 300px 0 100px;
}
.main-bar {
  position: absolute;
  width: 35%;
  height: 100%;
  right: 0;
  top: 0;
}
.main-bar-inner {
  position: absolute;
  background-color: #001e53;
  width: 50%;
  right: 0;
  top: 0;
  height: 100%;
}
.headline-container {
  position: relative;
}
.headline-container h2 {
  font-family: "roc-grotesk-wide", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  font-size: 54px;
  line-height: 74px;
  position: relative;
  color: #001e53;
}
.headline-container p {
  font-size: 21px;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 400;
  color: #001e53;
}
.headline-link {
  font-family: "roc-grotesk-wide", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 34px;
  line-height: 88px;
  color: #001e53;
  text-decoration: none;
  transition: 0.2s;
}

.headline-link:link,
.headline-link:active,
.headline-link:visited {
  text-decoration: none;
  color: #001e53;
}

ul {
  list-style: none;
  padding: 0;
}

.footer-container {
  height: auto;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 180px;
  flex-direction: column-reverse;
}
.footer-container-inner {
  margin-top: 180px;
}
.footer-divide {
  height: 1px;
  width: 100%;
  margin: 48px 0 60px;
  background-color: #001e53;
}

.footer-detail {
  font-family: "roc-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #001e53;
  font-size: 22px;
  line-height: 31px;
}
.footer-detail a:visited,
.footer-detail a {
  text-decoration: none;
  color: #001e53;
  transition: 0.2s;
}
.footer-name {
  line-height: 32px;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.footer-base {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer-credit {
  position: absolute;
  right: 74px;
  bottom: 30px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  color: #ffffff;
  text-decoration: none;
  font-size: 13px;
  margin-bottom: 20px;
  z-index: 3;
}
.footer-privacy {
  position: absolute;
  left: 74px;
  bottom: 30px;
  font-family: "Satoshi-Regular", sans-serif;
  font-style: normal;
  color: #001e53;
  text-decoration: none;
  font-size: 13px;
  margin-bottom: 20px;
}

.footer-privacy a:visited,
.footer-privacy a {
  color: #001e53;
  text-decoration: none;
  transition: 0.2s;
}
.footer-credit a:visited,
.footer-credit a {
  color: #ffffff;
  text-decoration: none;
  transition: 0.2s;
}

.footer-icon-savills {
  width: 112px;
  height: auto;
}

@media (max-width: 1399.98px) {
  .headline-container h2 {
    font-size: 46px;
    line-height: 64px;
  }
  .footer-detail {
    font-size: 18px;
    line-height: 25px;
  }
}

@media (max-width: 1199.98px) {
  .headline-container h2 {
    font-size: 38px;
    line-height: 48px;
  }
  .footer-detail {
    font-size: 15px;
    line-height: 22px;
  }
  .footer-credit {
    right: 45px;
  }
  .footer-privacy {
    left: 45px;
  }
}

@media (max-width: 991.98px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .header-text {
    left: 1.5rem;
  }
  .header-logo-mobile {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    width: 100px;
  }
  .header-logo {
    top: 20px;
    left: auto;
    right: 1.5rem;
    transform: translateX(0%);
  }
  .footer-credit {
    right: 12px;
  }
  .footer-privacy {
    left: 12px;
  }
  .window-center {
    width: 256px;
  }
  .window-text {
    margin-top: 34px;
  }
  .window-inner {
    width: 120px;
    height: 142px;
  }
  .section-main {
    height: auto;
    padding: 260px 0 160px;
    background-position: top;
  }
  .headline-container {
    width: 80%;
  }
  .headline-container h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .headline-container p {
    font-size: 16px;
  }
  .headline-container {
    position: relative;
    top: auto;
    transform: translateY(0);
  }
  .headline-link {
    line-height: 50px;
    font-size: 22px;
  }
  .footer-container {
    height: auto;
  }
  .footer-icon-savills {
    width: 100px;
  }
  .footer-title {
    text-align: left;
    margin-bottom: 60px;
  }
  .footer-detail {
    font-size: 16px;
    line-height: 23px;
  }
  .footer-top {
    padding: 80px 0;
  }
  .footer-mid {
    padding: 80px 0 100px;
  }
  .footer-bottom {
    padding: 30px 0 30px;
  }
}

@media (max-width: 767.98px) {
  .main-bar {
    display: none;
  }
  .section-main {
    padding: 200px 0 160px;
  }
  .footer-container-inner {
    margin-top: 100px;
  }
  .headline-link {
    line-height: 38px;
    font-size: 18px;
  }
  .headline-container {
    width: 90%;
  }
  .headline-container h2 {
    font-size: 26px;
    line-height: 35px;
  }
  .intro-arrow {
    bottom: 77px;
  }
  .footer-divide {
    margin: 38px 0 40px;
  }
  .section-main {
    background-image: url(../imgs/curve_design_mobile.svg);
    background-size: 100% auto;
    background-position: left top;
    background-repeat: no-repeat;
  }
  .mobile-curve {
    display: block;
    width: 100%;
    height: auto;
    transform: scale(1, -1);
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .footer-privacy,
  .footer-credit {
    bottom: 10px;
  }
  .footer-credit {
    right: 24px;
  }
  .footer-privacy {
    left: 24px;
  }
  .footer-privacy,
  .footer-privacy a:visited,
  .footer-privacy a {
    z-index: 2;
    color: #ffffff;
    text-decoration: none;
    transition: 0.2s;
  }
}

@media (max-width: 575.98px) {
  .footer-credit {
    right: 12px;
  }
  .footer-privacy {
    left: 12px;
  }
  .headline-container {
    width: auto;
  }
  .headline-container h2 {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 389.99px) {
  .headline-container h2 {
    font-size: 19px;
    line-height: 28px;
  }
}

@media (hover: hover) {
  .headline-link:hover,
  .footer-privacy a:hover,
  .footer-credit a:hover,
  .footer-detail a:hover {
    color: #816943;
  }
  .button-download:hover {
    background-color: rgba(253, 197, 172, 0.4);
  }
}
